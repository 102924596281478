












































import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { TagCollectionItemModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import { ConfirmCpt } from "@/core/components/common";

@Component({
  components: {
    ConfirmCpt,
  },
})
export default class TagsView extends AppVue {
  showConfirm = false;
  tagIdToDelete = 0;
  deleteMessage =
    "Are you sure you want to delete this Tag? This action will also untag any volunteers tagged with it.";

  models = [] as TagCollectionItemModel[];

  async created() {
    await this.loadTags();
  }

  async loadTags() {
    const response = await ApiService.tags().getAllTags();
    this.models = response.data;
  }

  confirmDeleteTag(tagId: number) {
    this.tagIdToDelete = tagId;
    this.showConfirm = true;
  }

  async deleteTag(isConfirmed: boolean) {
    try {
      if (!isConfirmed || !this.tagIdToDelete) return;

      this.isLoading = true;
      await ApiService.tags().deleteTag(this.tagIdToDelete);
      NotificationProvider.success("Tag deleted.");
      await this.loadTags();
    } finally {
      this.tagIdToDelete = 0;
      this.showConfirm = false;
    }
  }
}
